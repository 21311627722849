@import 'variables';

body {
  padding-top: 50px;
}

img, svg {
  max-width: 100%;
}

.navbar-main .navbar-brand {
  width: 600px;
}

.navbar-contact {
  margin-top: -50px;
  margin-bottom: 20px;
}

.navbar-nav.site-nav {
  .dropdown-menu {
    left: 0;
    border-radius: 0;
    border: none;
    width: 310px;
    box-shadow: 0 4px 40px rgba(0,0,0,.4);

    .dropdown-item {
      padding: 0.5rem 1.5rem;
    }
  }
}

.svg-link {
  svg {
    display: inline-block;
    vertical-align: middle;
  }
  svg + span {
    margin-left: 4px;
    display: inline-block;
  }
}

.page-header {
  margin-bottom: 2em;
}
.page-content {
  padding-top: 2em;
}

.faq-question {
  margin-bottom: 40px;

  svg {
    vertical-align: top;
    fill: #CCC;
  }
}

.dropdown.dropdown-hover:hover > .dropdown-menu {
  display: block;
}

blockquote.testimonial {
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid #eee;
  border-left: .25rem solid $blue;
  border-radius: .25rem;
}

.turbolinks-progress-bar {
  height: 5px;
  background-color: $blue;
}

.funksters {
  margin: 4rem 0;

  .pic {
    text-align: center;
    height: 270px;
    background: #9a9a9a url(/images/raving-funksters.jpg) no-repeat center center;
    background-size: 1243px 270px;
    margin-top: 2rem;
  }

}


.card {
  .icon-wrapper {
    display: block;
    position: absolute;
    margin-bottom: 12px;
    background: white;
    padding: 20px;
    border-radius: 50%;
    box-shadow: 0 0 13px rgba(0,0,0,.1);
    z-index: 1000;
    left: -13px;
    top: -11px;

    svg {
      display: inline-block;
      width: 50px;
      height: 50px;
      fill: rgba(0, 0, 0, 0.8);
    }
  }

  &:hover .icon-wrapper svg {
    fill: rgba(0, 0, 0, 0.9);
  }

  .icon-wrapper + .card-title {
    padding-left: 70px !important;
  }

  .card-faux-header .card-title {
    position: relative;
    display: inline-block;
    float: left;
    width: 100%;
    height: 40px;
    transition: all 0.25s;
    padding: 0 20px;
    background-color: lighten($blue, 10%);

    &:after {
      position: absolute;
      background-color: $blue;
      opacity: 0.8;
      z-index: 9;
      content: "";
      transition: all 0.25s;
      top: 0;
      width: 0;
      left: 0;
      height: 100%;
    }

    span {
      position: absolute;
      top: 6px;
      color: white;
      text-shadow: 1px 1px 0 black;
      font-weight: 700;
      z-index: 10;
    }
  }

  &:hover .card-faux-header .card-title:after {
    width: 100%;
  }
}

.list-group.side-nav {
  border-radius: 0;

  .list-group-item {
    border-width: 0 2px 0 0;

    &:first-child, &:last-child {
      border-radius: 0;
    }
  }
}

.trust-icons {
  padding: 60px 0;
}

.trust-icon {
  padding: 0 20px;

  h4 {
    text-align: center;
    margin: 1.3rem 0;
  }

  .icon {
    display: block;
    margin: 0 auto 1rem;
    text-align: center;

    &.funky svg {
      width: 80px;
      height: 80px;
      path, rect {
        fill: $blue;
      }
    }

    &.registered-building-practitioner svg {
      width: 400px;
      height: 80px;
    }
    &.monier img {
      width: 400px;
      margin: 20px 0;
    }
  }

  p {
    text-align: center;
  }
}

/* remove dropdown arrows */
.dropdown-toggle::after {
  display:none
}

.card.services-card {
  border: none;
  .card-img-top {
    filter: blur(5px);
    height: 140px;
  }
}

.jumbotron-video {
  background: url(/images/monier-elemental-shingle.jpg);
  border-radius: 0;
}

.svg-title svg {
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.25rem;
}
