footer.site-footer {
  margin-top: 40px;
  padding-top: 40px;
  padding-bottom: 80px;

  background-color: #e9ecef;
  color: #555;

  .copyright {
    font-size: 12px;
  }
}
