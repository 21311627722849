ul.articles {
  list-style: none;
  margin: 0;
  padding: 0;

  > li {
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #eee;
  }
}
