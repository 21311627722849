

a.card {
  .card-body {
    color: $gray-700;
    .card-title {
      color: $gray-900;
    }
  }
  .card-link {
    color: $blue;
  }

  &:hover {
    text-decoration: none;
    .card-link {
      text-decoration: underline;
    }
  }
}

.services-card {
  margin-bottom: 3rem;
}
