@import "variables";
@import "mixins";

.jumbotron {
  color: #FFF;
  a {
    color: #FFF;
  }

  h1 {
    text-shadow: 1px 1px 2px black;
  }

  &.hero {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .strip {
      position: relative;
      padding: 1rem 0;
      margin: 1rem 0 16rem;

      &:after {
        background-color: $gray-800;
        position: absolute;
        opacity: 0.8;
        z-index: 9;
        content: "";
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
      > div {
        padding: 0 2em;
        z-index: 10;
        position: relative;
        h1 {
          text-shadow: 1px 1px 0 black;
        }
      }
    }

    #nullabor {
      position: absolute;
      bottom: -80px;
      left: -40px;
      width: 320px;
      height: 160px;
    }
    #nouveau {
      position: absolute;
      top: -25px;
      left: -45px;
      height: 206px;
      width: 206px;
    }
  }

  padding-left: 0;
  padding-right: 0;
  background-size: cover;
  background-position: center center;

}

.jumbotron.jumbotron-testimonials {
  .jumbotron-testimonial {
    margin-top: 2rem;
    position: relative;

    .quote-mark {
      position: absolute;
      left: -46px;
      fill: white;
    }
  }
}

.jumbotron {
  min-height: 300px;
}

@include media-breakpoint-up(md) {
  .jumbotron {
    min-height: 600px;
  }
}

@include media-breakpoint-down(md) {
  #nullabor,
  #nouveau {
    display: none;
  }
}
